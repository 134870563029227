export const useFaqSchema = () => {
  const faqSchemas = useState('faqSchemas', () => [] as any[]);
  
  const addToSchema = (newFaqs: any[]) => {
    // Add new FAQs if they don't already exist
    newFaqs.forEach(newFaq => {
      const exists = faqSchemas.value.some(existingFaq => 
        existingFaq.name === newFaq.name
      );
      if (!exists) {
        faqSchemas.value.push(newFaq);
      }
    });
  };

  const getFullSchema = () => ({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faqSchemas.value
  });

  return {
    addToSchema,
    getFullSchema
  };
}; 