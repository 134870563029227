<template>
  <div 
    v-editable="blok" 
    class="relative" 
  >
    <div
      class="mx-auto max-w-7xl py-6 px-4 lg:py-24 sm:px-6 lg:px-8 relative z-30"
    >
      <div class="mx-auto max-w-3xl text-center">
        <h2
          v-if="blok.title"
          class="heading-2"
        >
          {{ blok.title }}
        </h2>
        <h3 v-if="blok.subtitle" class="pt-5 max-w-2xl m-auto text-lg text-grey-dark">
          {{ blok.subtitle }}
        </h3>
      </div>

      <div class="mx-auto max-w-5xl" :class="{'mt-6 lg:mt-16': blok.title}">
        <Faq
          v-for="faq in blok.faqs"
          :key="faq.question"
          :faq="faq"
        >
        </Faq>
      </div>

      <div v-if="blok.footerTitle" class="text-center mt-10 lg:mt-20">
        <h4 class="subtitle-1">{{ blok.footerTitle }}</h4>
        <div class="mt-2 lg:mt-6 text-grey-dark font-normal text-base" v-html="resolvedRichText"></div>
        <Button class="mt-6" as="nuxt-link" to="/contact-us" size="lg">Contact us</Button>
      </div>
    </div>

    <Gradients :gradients="blok.gradients"/>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object, filter: String });
const { addToSchema, getFullSchema } = useFaqSchema();

// Convert rich text to plain text
const getPlainTextAnswer = (answer) => {
  if (!answer) return '';
  
  if (answer.content) {
    return renderRichText(answer, {
      resolver: (component, blok) => {
        return '';
      },
      textResolver: (text) => text.replace(/<[^>]*>/g, '')
    });
  }
  
  return answer;
};

// Create FAQ entries
const faqs = computed(() => 
  props.blok.faqs.map(faq => ({
    '@type': 'Question',
    'name': faq.question,
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': getPlainTextAnswer(faq.answer)
    }
  }))
);

// Add FAQs to the global schema
addToSchema(faqs.value);

// Add schema to head
useHead({
  script: [
    {
      type: 'application/ld+json',
      innerHTML: computed(() => JSON.stringify(getFullSchema()))
    }
  ]
});

// Original computed property for visible content
const resolvedRichText = computed(() =>
  renderRichText(props.blok.footerSubtitle, {
    resolver: (component, blok) => {
      return `<component :blok='${JSON.stringify(
        blok
      )}' is="${component}"></component>`;
    },
  })
);
</script>
